import { AATColumnTemplate } from "./../../../home/audience-analysis-tool/compare-audiences/aat-column-template";
export enum Region {
  UK = "UK",
  US = "US",
  IE = "IE",
  CA = "CA",
  INT = "INT",
}

export enum SearchGridActiveColumns {
  UK = "active_columns",
  US = "active_columns_us",
}

export enum CampaignGridActiveColumns {
  UK = "campaign_active_columns",
  US = "campaign_active_columns_us",
}

export interface HeaderConfig {
  freeTextSearch: boolean;
  inAppNotification: boolean;
}

export interface ResultsViewConfig {
  kpiDetails: boolean;
}

export interface CampaignDetailsConfig {
  kpiSummary: boolean;
  containerGrid: boolean;
}

export interface LeftSideBarConfig {
  format: boolean;
  frameType: boolean;
  formatGroup: boolean;
  formatFormat: boolean;
  environment: boolean;
  availabilityDate: boolean;
  daypart: boolean;
  availability: boolean;
  frameOwner: boolean;
  directions: boolean;
  frames: boolean;
  frameIds: boolean;
  frameOthers: boolean;
  frameExclude: boolean;
  audience: boolean;
  geography: boolean;
  country: boolean;
  tvRegion: boolean;
  town: boolean;
  cbsa: boolean;
  city: boolean;
  conurbation: boolean;
  geoPostalCode: boolean;
  geoPostalArea: boolean;
  marketArea: boolean;
  state: boolean;
  county: boolean;
  zipcode: boolean;
  poiAndProximity: boolean;
  poiPostalCode: boolean;
  poi: boolean;
  poiCategory: boolean;
  poiBrand: boolean;
  poiBusiness: boolean;
  poiDistance: boolean;
}

export interface GridConfig {
  captions: { [key: string]: string };
  filterCaptions: { [key: string]: string };
  columns: { [key: string]: string };
  searchGridColumns: string[];
  searchGridActiveColumnsPreference: string;
  campaignGridActiveColumns: string;
  columnsWidth: { [key: string]: number };
  columnsSortable: { [key: string]: boolean };
  columnsDraggable: { [key: string]: boolean };
  mandatoryColumns: string[];
  userSelectedColumns: string[];
  pinnedRightColumns: string[];
  features: { [key: string]: string };
  featuresEnabled: { [key: string]: boolean };
}

export interface AATConfig {
  isRouteVersionSelectionMandatory: boolean;
  isFileUploadEnabled: boolean;
  isAddAudienceEnabled: boolean;
  isGeoTargetingEnabled: boolean;
  aatEntryUrl: string;
  analyticalSystem: string;
  columns: AATColumnTemplate[];
}

export interface MapConfig {
  defaultZoom: number;
  shouldUpdatePrice: boolean;
  showPrice: boolean;
  listenPOIRenderingStatus: boolean;
  updateDMAAndCountyBoundaries: boolean;
}
