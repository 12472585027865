import { getConfigForRegion } from "src/configuration";
import { Injectable } from "@angular/core";

const REGION_KEY = "region";

@Injectable({
  providedIn: "root",
})
export class ConfigurationService {
  public static environment;

  constructor() {
    ConfigurationService.environment = getConfigForRegion(this.getCurrentRegion());
  }

  setTestRegion(region: string) {
    localStorage.setItem(REGION_KEY, region);
    ConfigurationService.environment = getConfigForRegion(region);
  }

  setCurrentRegion(region: string) {
    if (!this.getSupportedRegions().includes(region)) {
      console.log(`Region ${region} is not supported. Reverting to default region.`);
      region = this.getDefaultRegion();
    }
    localStorage.setItem(REGION_KEY, region);
    ConfigurationService.environment = getConfigForRegion(region);
  }

  getCurrentRegion(): string {
    return localStorage.getItem(REGION_KEY);
  }

  getConfig() {
    return getConfigForRegion(this.getCurrentRegion());
  }

  getDefaultRegion(): string {
    const environment = this.getConfig();
    return environment.defaultRegion;
  }

  getSupportedRegions(): string[] {
    const environment = this.getConfig();
    return environment.supportedRegions;
  }
}
