import { ConfigurationService } from "src/app/core/svc/configuration-service";
import { Price } from "../models/domain/price";
import moment from "moment";
import { SOT_PRECISION } from "./constants";
import { Campaign } from "../models/domain/Campaign";

const environment = new ConfigurationService().getConfig();
const campaignConfig = environment.campaignConfig;

export const CURRENCY_EXCHANGE_BASE_URL = "/inventory/exchange-rates";

export const hourlyFromTimes = [
  { label: "12:00 AM", value: "00:00" },
  { label: "01:00 AM", value: "01:00" },
  { label: "02:00 AM", value: "02:00" },
  { label: "03:00 AM", value: "03:00" },
  { label: "04:00 AM", value: "04:00" },
  { label: "05:00 AM", value: "05:00" },
  { label: "06:00 AM", value: "06:00" },
  { label: "07:00 AM", value: "07:00" },
  { label: "08:00 AM", value: "08:00" },
  { label: "09:00 AM", value: "09:00" },
  { label: "10:00 AM", value: "10:00" },
  { label: "11:00 AM", value: "11:00" },
  { label: "12:00 PM", value: "12:00" },
  { label: "01:00 PM", value: "13:00" },
  { label: "02:00 PM", value: "14:00" },
  { label: "03:00 PM", value: "15:00" },
  { label: "04:00 PM", value: "16:00" },
  { label: "05:00 PM", value: "17:00" },
  { label: "06:00 PM", value: "18:00" },
  { label: "07:00 PM", value: "19:00" },
  { label: "08:00 PM", value: "20:00" },
  { label: "09:00 PM", value: "21:00" },
  { label: "10:00 PM", value: "22:00" },
  { label: "11:00 PM", value: "23:00" },
];

export const hourlyToTimes = [
  { label: "12:59 AM", value: "00:59" },
  { label: "01:59 AM", value: "01:59" },
  { label: "02:59 AM", value: "02:59" },
  { label: "03:59 AM", value: "03:59" },
  { label: "04:59 AM", value: "04:59" },
  { label: "05:59 AM", value: "05:59" },
  { label: "06:59 AM", value: "06:59" },
  { label: "07:59 AM", value: "07:59" },
  { label: "08:59 AM", value: "08:59" },
  { label: "09:59 AM", value: "09:59" },
  { label: "10:59 AM", value: "10:59" },
  { label: "11:59 AM", value: "11:59" },
  { label: "12:59 PM", value: "12:59" },
  { label: "01:59 PM", value: "13:59" },
  { label: "02:59 PM", value: "14:59" },
  { label: "03:59 PM", value: "15:59" },
  { label: "04:59 PM", value: "16:59" },
  { label: "05:59 PM", value: "17:59" },
  { label: "06:59 PM", value: "18:59" },
  { label: "07:59 PM", value: "19:59" },
  { label: "08:59 PM", value: "20:59" },
  { label: "09:59 PM", value: "21:59" },
  { label: "10:59 PM", value: "22:59" },
  { label: "11:59 PM", value: "23:59" },
];

export function joinUrl(base: string, endpoint: string): string {
  return trimSlashes(base) + "/" + trimSlashes(endpoint);
}

export function trimSlashes(raw: string): string {
  let processedString = raw;

  while (isSlash(processedString[0])) {
    processedString = processedString.slice(1, processedString.length);
  }

  while (isSlash(processedString[lastCharacterIndex(processedString)])) {
    processedString = processedString.slice(0, lastCharacterIndex(processedString));
  }

  return processedString;
}

export function isSlash(char): boolean {
  return char === "/" || char === "\\";
}

export function lastCharacterIndex(val: string): number {
  return val.length - 1;
}

export const has =
  <T>(item: T) =>
  (list: T[]) =>
    list.some((x) => item === x);
export const add =
  <T>(item: T) =>
  (list: T[]) =>
    list.concat(item);
export const remove =
  <T>(item: T) =>
  (list: T[]) =>
    list.filter((x) => x !== item);
export const toggle =
  <T>(item: T) =>
  (list: T[]) =>
    has(item)(list) ? remove(item)(list) : add(item)(list);
export const get = (relationship: string) => (item: object) => {
  try {
    return relationship.split(".").reduce((obj, key) => obj[key], item);
  } catch {
    return undefined;
  }
};
export const toPairs = <T extends object>(obj: T): [string, any][] => {
  if (Object.entries != undefined) {
    return Object.entries(obj);
  }
  const pairs = [];
  for (const key in obj) {
    pairs.push([key, obj[key]]);
  }
  return pairs;
};

export const fromPairs = <T>(pairs: [string, any][]) => {
  const result = {};
  pairs.forEach(([key, value]) => (result[key] = value));
  return result as T;
};
export const unique = <T>(items: T[]) => {
  const length = items.length;
  const values: T[] = [];
  const uniques = new Set<T>();
  for (let index = 0; index < length; index++) {
    const value = items[index];
    if (uniques.has(value)) {
      continue;
    }
    uniques.add(value);
    values.push(value);
  }
  return values;
};
export const scheduleMicroTask = (task: () => void) => {
  new Promise(task);
};
export const scheduleMacroTask = (task: () => void) => {
  setTimeout(task, 1);
};

export const transformCamelCaseToRegularForm = (inputString: string) => {
  return inputString.replace(/([A-Z])/, " $1").replace(/^./, (str) => str.toUpperCase());
};

export const populateCampaign = (campaign: Campaign) => {
  return {
    id: campaign.id,
    "Campaign Name": campaign.campaignName,
    Agency: campaign.mediaAgency,
    Client: campaign.clientName,
    Brand: campaign.brandName,
    Owner: campaign.campaignCreator,
    "Created On": getUTCDate(new Date(campaign.campaignCreationDate)),
    "Start Date": campaign.startDate ? getUTCDate(new Date(campaign.startDate)) : "",
    "End Date": campaign.endDate ? getUTCDate(new Date(campaign.endDate)) : "",
    Budget: campaign.mediaBudgetGross,
    "Team Name": campaign.team,
    Status: campaign.campaignStatusText,
  };
};
export const populateCampaignForListing = (campaign: Campaign) => {
  return {
    id: campaign.id,
    "Campaign Name": campaign.campaignName,
    Agency: campaign.mediaAgency,
    Client: campaign.clientName,
    Brand: campaign.brandName,
    Owner: campaign.campaignCreator,
    "Created On": getUTCDate(new Date(campaign.campaignCreationDate)),
    "Start Date": campaign.startDate ? campaign.startDate : "",
    "End Date": campaign.endDate ? campaign.endDate : "",
    Budget: campaign.mediaBudgetGross,
    "Team Name": campaign.team,
    "Campaign Probability":
      campaign.probabilityPercentage || campaign.probabilityPercentage === 0
        ? campaign.probabilityPercentage.toString() + "%"
        : "--",
    Status: campaign.campaignStatusText,
  };
};
export function getUTCDate(localDate: Date) {
  return new Date(localDate.getUTCFullYear(), localDate.getUTCMonth(), localDate.getUTCDate());
}
export function getLocalDate(localDate: Date) {
  return new Date(localDate.getFullYear(), localDate.getMonth(), localDate.getDate());
}
export function localeDateToLocaleDateString(localeDate: Date) {
  let date = "";
  if (campaignConfig.isDateFormatDDMMYYYY) {
    date =
      ("0" + localeDate.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (localeDate.getUTCMonth() + 1)).slice(-2) +
      "/" +
      localeDate.getUTCFullYear();
  } else {
    date =
      ("0" + (localeDate.getUTCMonth() + 1)).slice(-2) +
      "/" +
      ("0" + localeDate.getUTCDate()).slice(-2) +
      "/" +
      localeDate.getUTCFullYear();
  }
  return date;
}
export function localeDateTimeToLocaleDateString(localeDate: Date) {
  let date = "";
  if (campaignConfig.isDateFormatDDMMYYYY) {
    date =
      ("0" + localeDate.getUTCDate()).slice(-2) +
      "/" +
      ("0" + (localeDate.getUTCMonth() + 1)).slice(-2) +
      "/" +
      localeDate.getUTCFullYear() +
      " " +
      ("0" + localeDate.getHours()).slice(-2) +
      ":" +
      ("0" + localeDate.getMinutes()).slice(-2);
  } else {
    date =
      ("0" + (localeDate.getUTCMonth() + 1)).slice(-2) +
      "/" +
      ("0" + localeDate.getUTCDate()).slice(-2) +
      "/" +
      localeDate.getUTCFullYear() +
      " " +
      ("0" + localeDate.getHours()).slice(-2) +
      ":" +
      ("0" + localeDate.getMinutes()).slice(-2);
  }
  return date;
}
export function utcDateStringToLocaleDate(utcDateString: string) {
  const utcDate = new Date(utcDateString);
  return new Date(
    utcDate.getUTCFullYear(),
    utcDate.getUTCMonth(),
    utcDate.getUTCDate(),
    utcDate.getUTCHours(),
    utcDate.getUTCMinutes(),
    utcDate.getUTCSeconds(),
    utcDate.getUTCMilliseconds()
  );
}
export function toUTCStartOfTheDay(date: Date) {
  const utcStartOfTheDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
  return utcStartOfTheDay;
}

export function toUTCEndOfTheDay(date: Date) {
  const utcEndOfTheDay = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999));
  return utcEndOfTheDay;
}

/**
 * function to perform sort of alpha-numeric strings with intelligence to handle numeric prefixes.
 */
export function compareLists(a, b) {
  const alist = a.split(/(\d+)/),
    blist = b.split(/(\d+)/);

  alist.slice(-1) === "" ? alist.pop() : null;
  blist.slice(-1) === "" ? blist.pop() : null;

  for (let i = 0, len = alist.length; i < len; i++) {
    if (alist[i] !== blist[i]) {
      if (alist[i].match(/\d/)) {
        return +alist[i] - +blist[i];
      } else {
        return alist[i].localeCompare(blist[i]);
      }
    }
  }

  return true;
}

export function getDateFormat() {
  return environment.Formats.dateFormat;
}

export function getDateRangeConfig() {
  return Object.assign(
    {},
    {
      rangeInputFormat: getDateFormat(),
      containerClass: "theme-blue",
      showWeekNumbers: false,
    }
  );
}

export function getMonthPickerConfig() {
  return {
    defaultMonth: new Date(),
    dateInputFormat: "MMMM YYYY",
    containerClass: "theme-blue",
    showWeekNumbers: false,
    minMode: "month",
  };
}

export const defaultDayPartSortingOrder = (item1, item2): number => {
  if (item1.label < item2.label) {
    return -1;
  }
  if (item1.label > item2.label) {
    return 1;
  }
  return 0;
};

export const customInputNumber = (event) => {
  const keyCode = event.which ? event.which : event.keyCode;
  const value = event.target.value;
  if (value.includes(".") && (keyCode === 190 || keyCode === 46)) {
    event.preventDefault();
  }
  if (keyCode === 13 && event.target.value === "") {
    return false;
  }
  if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || keyCode === 190 || keyCode === 46) {
    return true;
  }
  event.preventDefault();
};

export function hex(len) {
  const letters = "0123456789abcdef";
  let hexString = "";
  for (let i = 0; i < len; i++) {
    hexString += letters[Math.floor(Math.random() * 16)];
  }
  return hexString;
}

export function precisionRound(number, precision) {
  const factor = Math.pow(10, precision);
  return Math.round(number * factor) / factor;
}

export const getAdjustedUnitPrices = (
  totalValue: number,
  numberOfEntries: number,
  precision: number,
  sortIndices?: number[]
): number[] => {
  const averageValue: number = precisionRound(totalValue / numberOfEntries, precision);
  const diff: number = precisionRound(totalValue - averageValue * numberOfEntries, precision);

  const adjustCount = Math.abs(diff) * Math.pow(10, precision);
  const sign = diff < 0 ? -1 : 1;
  const adjustValue = Math.pow(0.1, precision);

  const result: number[] = [];

  for (let i = 0; i < numberOfEntries; i++) {
    result.push(averageValue);
  }
  const adjustedResult = result.map((num, index) => {
    if (index < adjustCount) return precisionRound(num + sign * adjustValue, precision);
    return num;
  });

  return adjustedResult.sort();
};

export const getPricePerSchedule = (
  newPrice: number,
  type: any,
  qty: any,
  isUnitPrice: boolean
): Array<number> | number => {
  const pricePerSchedule: number =
    isUnitPrice && type !== "FRAMEBUY" && type !== "NETWORK"
      ? precisionRound(newPrice * qty, 2)
      : !isUnitPrice && (type === "FRAMEBUY" || type === "NETWORK")
      ? newPrice / qty
      : newPrice;

  const finalPricing: Array<number> | number =
    !isUnitPrice && (type === "FRAMEBUY" || type === "NETWORK")
      ? getAdjustedUnitPrices(newPrice, qty, 2)
      : pricePerSchedule;

  return finalPricing;
};

export const addPrice = (value1: Price, value2: Price): Price => {
  if (value1.currency === value2.currency) {
    return { currency: value1.currency, value: value1.value + value2.value };
  }
  return undefined;
};

export const sumWithNullCheck = (elements: any[], defaultValue: null | number = 0) => {
  return elements.reduce((a, b) => {
    if (!b) {
      return a;
    }
    if (!a && b) {
      return b;
    }
    return a + b;
  }, defaultValue);
};

export const _window = () => {
  return window;
};

export const _document = () => {
  return document;
};

export const areDatesEqualWithoutTime = (firstDate: Date, secondDate: Date): boolean => {
  if (
    moment(firstDate).isSame(secondDate, "day") &&
    moment(firstDate).isSame(secondDate, "month") &&
    moment(firstDate).isSame(secondDate, "year")
  ) {
    return true;
  }
  return false;
};

export const calculateSOT = (spot: number, loop: number): number => {
  return loop ? precisionRound((spot / loop) * 100, SOT_PRECISION) : null;
};

export const sortByName = <T>(items: T[]) =>
  items.sort((a, b) => (a["name"] < b["name"] ? -1 : a["name"] > b["name"] ? 1 : 0));

export function getUniqueByKey(data, key) {
  return [...new Map(data.map((x) => [key(x), x])).values()];
}

export function copy(data: any, objectMap?: WeakMap<any, any>) {
  if (!objectMap) {
    // Map for handle recursive objects
    objectMap = new WeakMap();
  }

  // recursion wrapper
  const deeper = (value) => {
    if (value && typeof value === "object") {
      return copy(value, objectMap);
    }
    return value;
  };

  // Array value
  if (Array.isArray(data)) {
    return data.map(deeper);
  }

  // Object value
  if (data && typeof data === "object") {
    // Same object seen earlier
    if (objectMap.has(data)) {
      return objectMap.get(data);
    }
    // Date object
    if (data instanceof Date) {
      const result = new Date(data.valueOf());
      objectMap.set(data, result);
      return result;
    }
    // Use original prototype
    const node = Object.create(Object.getPrototypeOf(data));
    // Save object to map before recursion
    objectMap.set(data, node);
    for (const [key, value] of Object.entries(data)) {
      node[key] = deeper(value);
    }
    return node;
  }
  // Scalar value
  return data;
}

export const hasSameValues = (arr: any[]) => arr.every((val) => val === arr[0]);

export const convertToCSV = (data: any[], columnKeys: string[], columnHeaders?: string[]): string => {
  if (data.length === 0) {
    return "";
  }

  const escapeSpecialCharacters = (value: any): string => {
    if (value === undefined) {
      return "";
    }
    const stringValue = String(value);
    if (stringValue.includes(",") || stringValue.includes('"') || stringValue.includes("\n")) {
      return `"${stringValue.replace(/"/g, '""')}"`;
    }
    return stringValue;
  };

  const header = (columnHeaders ?? columnKeys).map(escapeSpecialCharacters).join(",");
  const rows = data.map((item) => columnKeys.map((key) => escapeSpecialCharacters(item[key])).join(","));

  return `${header}\n${rows.join("\n")}`;
};

export const localDateToISOString = (date: Date): string => {
  return new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())).toISOString().split("T")[0];
};
